.dashboard-column-1 {
    background-image: url("../../assets/images/img-greetings.png"), url("../../assets/images/bg-greetings.jpg");
    background-position: right bottom, left top;
    background-repeat: no-repeat, repeat;
    background-size: 200px, cover;
    border-color: #10B582;
}

.dashboard-column-1-box {
    background-image: url("../../assets/images/img-greetings.png");
    background-repeat: no-repeat;
    padding: 24px;
    /* opacity: .3; */
}

.dashboard-column-1-ucapan {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #101010;
    margin-bottom: 8px;
}

.dashboard-column-1-nama {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.1px;
    color: #101010;
}

.pengumuman {
    margin-top: 8px;
    color: #FF4D4F;
    font-size: 16px;
}

.pengumuman-text {
    color: #0d7446;
    font-size: 16px;
}

.dashboard-column {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    border-left: 2px solid #10B582;
}

.dashboard-column-icon {
    border-radius: 8px !important;
    margin-top: 8px;
}

.dashboard-column-count {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #000000;
}

.dashboard-column-title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    letter-spacing: 0.1px;

    color: #12784A;
}

.dashboard-title {
    position: absolute;
    width: 68px;
    height: 24px;
    left: 389px;
    top: 359px;

    /* Heading/Small */

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.1px;

    /* Primary/Black */

    color: #161616;
}

.informasi-lembaga-title {
    /* Heading/Medium */

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: 0.1px;

    color: #000000;

}

.informasi-lembaga-subtitle {
    /* Body/Medium */

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: 0.15px;

    color: #000000;

}

.informasi-lembaga-description {
    /* Body/Medium */

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: 0.15px;

    /* Secondary/Green */

    color: #12784A;

}

.informasi-lembaga-box {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    position: static;
    width: 100px;
    height: 90px;
    left: 0px;
    top: 0px;

    background: rgba(253, 83, 8, 0.1);
    border-radius: 8px;

    float: right;
    margin: 0 0 0 16px;
}

.informasi-lembaga-box-title {
    /* Label/Medium */

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    text-align: center;
    letter-spacing: 0.15px;

    /* Primary/Black */

    color: #161616;
}

.informasi-lembaga-box-value {
    /* Heading/Large */

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    /* identical to box height, or 133% */


    /* Primary/Orange */

    color: #FD5308;
}

.informasi-lembaga-identity-title {
    /* Label/Medium */

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    letter-spacing: 0.15px;

    /* Subtle/Grey */

    color: #12784A;
}

/* COLOR */
.color-jumlah-siswa {
    background-color: #E6F8F3 !important;
    border: none !important;
    color: #7fb77f !important;
}

.color-warning {
    background-color: #fff3e5 !important;
    border: none !important;
    color: #7fb77f !important;

}

.color-info {
    background-color: #e5f5fd !important;
    border: none !important;
    color: #12784a !important;
}

.color-success {
    background-color: #edf7ed !important;
    border: none !important;
    color: #7fb77f !important;
}


.dashboard-column .ant-btn:hover {
    color: #12784a;
    border-color: #12784a !important;
}


.skeleton-welcome,
.skeleton-welcome .ant-skeleton-input {
    width: 100%;
    margin: 0 0 8px 0px;
    border-radius: 8px;
    min-height: 130px;
}

.skeleton-statistik,
.skeleton-statistik .ant-skeleton-input {
    width: 100%;
    margin: 0 0 4px 0px;
    border-radius: 8px;
    min-height: 100px;
}

.skeleton-lembaga,
.skeleton-lembaga .ant-skeleton-input {
    width: 100%;
    margin: 0 0 8px 0px;
    border-radius: 8px;
    min-height: 250px;
}

.bold{
    font-weight: bold;
}

.ant-alert-description{
    font-weight: lighter;
}


@media only screen and (max-width: 600px) {
    .dashboard-column-1 {
        background-image: none;
    }
}