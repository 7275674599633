/* ant-layout-content */
h5 {
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 2em !important;
  font-family: 'Work Sans' !important;
}

.root {
  font-family: 'Work Sans';
}

.logo {
  height: 33px;
  margin: 16px;
  /* background-image: url("./assets/images/ppdb.png"); */
  background-size: 222px;
  background-repeat: no-repeat;
  background-position-y: 0px;
  min-height: 79px;
}

.site-layout {
  border-radius: 8px !important;
  overflow: auto;
}

.site-layout .site-layout-background {
  background: #fff !important;
  border-radius: 8px;
  margin-bottom: 24px;
}

.site-layout-background {
  padding: 24px;
}

.box-shadow {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.text-align-center {
  text-align: center;
}

.search-table {
  border-radius: 4px !important;
}

.ant-table.ant-table-small .ant-table-thead>tr>th {
  padding: 16px 8px !important;
  font-size: 90%;
}

.ant-table {
  overflow: auto;
}

.dropdown-filter {
  border-radius: 8px !important;
  -webkit-box-shadow: 0 0 5px 0.1px #D1D1D1 !important;
  box-shadow: 0 0 5px 0.1px #D1D1D1 !important;
}

.btn-border-radius {
  border-radius: 4px !important;
}

.btn-border-radius-right {
  border-radius: 0 4px 4px 0 !important;
}

.btn-border-radius-left {
  border-radius: 4px 0 0 4px !important;
}

.nav-profile {
  line-height: 0.7em;
  text-align: right;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
  float: right;
}

.ant-layout-header {
  padding: 0 16px !important;
  margin-bottom: 0 !important;
}

.btn-logout:hover {
  cursor: pointer;
  color: #12784a;
}

.spin-loading {
  margin: 20% 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: #ffffff;
  border-radius: 4px;
  height: 100%;
}

.spin-loading-content {
  margin: 8% 0;
  padding: 30px 50px;
  text-align: center;
  background: #ffffff;
  border-radius: 4px;
}

.content {
  background: #fff;
  border-radius: 8px;
}

.input-full {
  width: 100%;
}

/* NAV PAGE */
.nav-page {
  margin: 0 0 24px 0;
  padding: 8px 24px;
}

.nav-btn-page {
  /* margin-right: 8px; */
}

.text-align-right {
  text-align: right;
}

.btn-drawer {
  /* display: none !important; */
}

.rs-btn {
  border-radius: 4px !important;
}

.mand-card {
  border-radius: 8px !important;
}

.mand-card>.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 24px;
  border-left: 4px solid #12784a;
}

.mand-card-mini {
  border-radius: 8px !important;
}

.mand-card-mini>.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 24px;
  border-left: 4px solid #ffffff;
}

.site-page-header {
  /* border: 1px solid rgb(235, 237, 240); */
  padding: 8px 0px !important;
}

.breadcrumb-line {
  margin: 0 !important;
}

input:read-only {
  background-color: #fafafa;
  cursor: auto;
}

.container-vertical {
  min-height: 90px;
  position: relative;
  text-align: right;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

button[disabled=disabled],
button:disabled {
  opacity: .2;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
}

/* MUI CUSTOM */
.css-k4qjio-MuiFormHelperText-root {
  color: red !important;
}

.css-1k33q06 {
  width: 0.5em;
  height: 0.5em;
}


/* GRID */
.navbar-icon {
  grid-area: navbar-icon;
  text-align: center !important;
}

.navbar-title1 {
  grid-area: navbar-title1;
}

.navbar-title2 {
  grid-area: navbar-title2;
  font-weight: bold;
}

.navbar-icon2 {
  grid-area: navbar-icon2;
  text-align: center !important;
  margin-top: 30%;
}

.navbar-grid-container {
  display: grid;
  grid-template-areas:
    'navbar-icon navbar-title1 navbar-title1 navbar-title1 navbar-title1 navbar-icon2'
    'navbar-icon navbar-title2 navbar-title2 navbar-title2 navbar-title2 navbar-icon2';
  gap: 0px;
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.45);
}

.navbar-grid-container>div {
  text-align: left;
  padding: 4px 0;
}




/* ANT DESIGN CUSTOM */
.ant-layout,
.ant-layout * {
  font-family: 'Nunito Sans', sans-serif;
}

.ant-menu-item a:hover {
  color: #848080 !important;
}

.ant-layout-content {
  margin: 0 24px !important;
  border-radius: 8px;
}

/* Form Input */
.ant-input {
  border-radius: 4px;
}

.ant-drawer-close {
  position: absolute;
  right: 0;
}

.ant-drawer-body {
  padding: 8px 0px !important;
}

/* TABLE STYLE */
.table-log-activity .ant-list-item-meta-title,
.table-log-activity .ant-list-item-meta-description {
  color: #6c6363 !important;
  font-size: 60%;
}

.ant-table-tbody>tr>td {
  color: #575757;
  font-size: 90%;
}

.ant-dropdown-menu-item:hover {
  background-color: #12784A !important;
  color: #fff !important;
  cursor: pointer;
  width: 160px;
}

.ant-dropdown-menu {
  box-shadow: 0 0px 1px -2px rgb(0 0 0 / 12%), 0 1px 0px 0 rgb(0 0 0 / 8%), 0 1px 2px 2px rgb(0 0 0 / 5%);
  width: 160px;
}

.ant-dropdown-menu-title-content {
  font-size: 90%;
}

ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light {
  border: 0;
}

.ant-modal-confirm .ant-modal-body {
  padding: 24px 23px 16px !important;
}

.ant-notification-notice {
  padding: 16px 16px;
  border-radius: 8px;
}

.ant-menu-item-selected a:hover {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

a:focus {
  text-decoration: none !important;
}

a:active {
  text-decoration: none !important;
}

.table-history {
  cursor: pointer;
  float: right;
}

.ant-popover-buttons {
  display: none;
}

.ant-popover-inner-content {
  min-width: 160px;
}

.ant-tree .ant-tree-treenode {
  width: 100%;
}

.ant-tree .ant-tree-node-content-wrapper {
  border-bottom: 1px dotted #ddd;
  width: 100%;
}

.ant-btn-group-fullwidth {
  width: 100%;
}

.ant-list-item-meta-title {
  font-family: 'Nunito Sans', sans-serif;
}

ul.ant-dropdown-menu-items {
  margin: 0;
  padding: 0 8px;
  width: 160px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-table-small .ant-table-thead>tr>th {
  background-color: #fff !important;
  font-weight: bold;
  text-transform: uppercase;
  color: #5b5d59;
}

.ant-menu-title-content {
  color: #0d5735;
  font-family: Roboto, sans-serif;
}

/* BUTTON */
.ant-btn-primary {
  color: #fff !important;
  /* border-color: #0b4c2f !important; */
  border-color: #0d7446 !important;
  background: #12784A !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:hover {
  border-color: #0f623c !important;
  color: #fff !important;
  box-shadow: 0 8px 25px -8px #12784a;
}

.ant-ribbon {
  height: auto !important;
  padding: 4px 8px !important;
  background-color: #12784a !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #12784a !important;
  border-color: #12784a !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #12784a !important;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #12784a !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff !important;
  border-color: #12784a !important;
}

.ant-btn-icon-only.ant-btn-lg {
  height: none !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 90%;
}

.ant-upload-list-picture-card-container {
  width: 100% !important;
}

.ant-upload-list-picture-card-container img {
  width: 100% !important;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 100%;
}

.ant-upload-list-item-info {
  min-height: 200px;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  min-height: 200px;
}

.ant-upload-list-picture-card-container {
  min-height: 200px;
}

.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
  border: none;
  padding: 0;
}

.ant-upload-list-item-info {
  transition: background-color 0s;
}

.ant-upload-list-item-info {
  min-height: 0;
}

.ant-upload-list-item {
  margin-top: 0;
}

.css-1pnmrwp-MuiTypography-root {
  font-size: 90%;
}

.ant-modal-content {
  border-radius: 4px !important;
}

.ant-modal-header {
  border-radius: 4px 4px 0 0 !important;
}

.ant-form-item {
  margin-bottom: 8px !important;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 4px !important;
}

.ant-form-item-label>label {
  font-size: 12px !important;
}

.ant-pagination-item {
  border-radius: 16px !important;
  font-size: 70% !important;
  font-weight: bold !important;
}

.ant-pagination-item-active {
  background: #0d5735 !important;
  border-color: #0d5735 !important;
  color: #0d5735 !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination-item:hover {
  background-color: #eff2f5 !important;
}

.ant-pagination-item a:hover {
  color: #0d5735 !important;
}

.ant-pagination-item-active:hover {
  background-color: #0d4129 !important;
}

.ant-pagination-item-active a:hover {
  color: #fff !important;
  font-size: 120% !important;
}

.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: #eff2f5 !important;
  border-color: #eff2f5 !important;
  color: #000000 !important;
  border-radius: 16px !important;
  font-size: 60%;
  font-weight: bold;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
  font-size: 80%;
}

.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
  position: absolute;
  left: 0;
}

.ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #0d5735 !important;
}

/* .table-navbar .ant-input-affix-wrapper {
  width: auto !important;
  margin-left: 8px !important;
} */

/* .table-navbar button {
  margin-left: 4px !important;
} */

.ant-breadcrumb a {
  text-decoration: none;
}

.image-circle {
  border-radius: 100%;
}

.image-circle .ant-image-mask,
.ant-image-mask:hover {
  border-radius: 100%;
}

@media only screen and (max-width: 600px) {
  .ant-layout-content {
    margin: 0 16px !important;
    border-radius: 8px;
  }

  .ant-menu-item {
    border-radius: 8px;
  }

  .ant-layout-sider {
    display: none;
  }

  .ant-layout-sider-collapsed {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .ant-layout-sider {
    display: none;
  }

  .ant-layout-sider-collapsed {
    display: none;
  }
}





/* FONT CUSTOM */

span.icon-circle {
  font-size: 70%;
}

.ant-menu-item-selected .icon-sidebar {
  color: #ffffff !important;
}

.icon-sidebar {
  margin-right: 8px;
  color: #12784a !important;
}

.icon-tab-bar {
  /* color: #12784a !important; */
  font-size: 18px;
}

@font-face {
  font-family: 'icomoon';
  src: url('../src/assets/font/custom/icomoon.eot?y67tis');
  src: url('../src/assets/font/custom/icomoon.eot?y67tis#iefix') format('embedded-opentype'),
    url('../src/assets/font/custom/icomoon.ttf?y67tis') format('truetype'),
    url('../src/assets/font/custom/icomoon.woff?y67tis') format('woff'),
    url('../src/assets/font/custom/icomoon.svg?y67tis#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bk:before {
  content: "\e900";
}

.icon-circle:before {
  content: "\e901";
}

.icon-dashboard:before {
  content: "\e902";
}

.icon-kesiswaan:before {
  content: "\e903";
}

.icon-keuangan:before {
  content: "\e904";
}

.icon-kurikulum:before {
  content: "\e905";
}

.icon-lembaga:before {
  content: "\e906";
}

.icon-logout:before {
  content: "\e907";
}

.icon-menu:before {
  content: "\e908";
}

.icon-peserta-didik:before {
  content: "\e909";
}

.icon-profile:before {
  content: "\e90a";
}

.icon-PTK:before {
  content: "\e90b";
}

.icon-rombel:before {
  content: "\e90c";
}

.icon-tatibsi:before {
  content: "\e90d";
}


/* PAGINATION MANDIGITAL */
.ant-pagination{
  text-align: right;
}

.ant-pagination-item {
  border-radius: 16px !important;
  font-size: 70% !important;
  font-weight: bold !important;
}

.ant-pagination-item-active {
  background: #0d5735 !important;
  border-color: #0d5735 !important;
  color: #0d5735 !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination-item:hover {
  background-color: #eff2f5 !important;
}

.ant-pagination-item a:hover {
  color: #0d5735 !important;
}

.ant-pagination-item-active:hover {
  background-color: #0d4129 !important;
}

.ant-pagination-item-active a:hover {
  color: #fff !important;
  font-size: 120% !important;
}

.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: #eff2f5 !important;
  border-color: #eff2f5 !important;
  color: #000000 !important;
  border-radius: 16px !important;
  font-size: 60%;
  font-weight: bold;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
  font-size: 80%;
}

.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
  position: absolute;
  left: 0;
}

.ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #0d5735 !important;
}

/* SORT & FILTER BUTTON */

.float-btn-center {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 60px;
  right: 0;
  z-index: 1;
  transition: bottom 0.3s;
}

.float-btn-div {
  background: #0e784a;
  width: fit-content;
  border-radius: 4px
}
.float-btn {
  border: none !important;
}

.float-btn-left {
  border-radius: 4px 0 0 4px !important;
}

.float-btn-right {
  border-radius: 0 4px 4px 0 !important;
}

.divider-border-white{
  border-left: 1px solid rgb(255 255 255) !important;
}

.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 2px #12784a1f !important;
}
.ant-switch-checked {
  background: #12784a !important;
}

.ant-alert-with-description {
  border-radius: 8px !important;
}

label.input-label {
  background: #fff;
  margin-bottom: 9px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0 8px;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  left: 8px;
  top: 0;
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-picker-input > input {
  font-size: .8rem !important;
}
.input-error{
  color: red;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 0.75rem;
}

.input-label-disable{
  padding: 5px 14px;
    border: 1px solid #bbb;
    border-radius: 4px;
    cursor: not-allowed;
}