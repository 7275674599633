body{
    min-height:500px
}

footer {
    background: #f0ebf8 !important;
}

.form-box {
    background-color: #fff;
    min-height: 100px;
    margin: 16px;
    border-radius: 8px;
    padding: 24px;
}

.form-box-header {
    border-top: solid 10px #12784A;
}

.form-box-input {
    margin-top: 8px !important;
}

.required,
.css-wgai2y-MuiFormLabel-asterisk {
    color: red;
}

.ant-picker {
    border-radius: 4px;
    padding: 5px 8px;
    border-color: #c4c4c4;
    width: 100%;
}

.btn-form-registrasi{
    float: right;
    border-radius: 4px;
}

.form-box .css-k4qjio-MuiFormHelperText-root{
    margin-left: 0;
}

.css-z1o969-MuiInputBase-root-MuiOutlinedInput-root{
    font-size: 12px !important;
}