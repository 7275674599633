#root {
    height: 100%;
    background-color: #E5E5E5;
}

.login-background {
    background: url(https://assetsatria.man1gresik.sch.id/ppdb/images/background-login.webp) no-repeat;
    background-color: #12784A;
    background-size: cover;
    background-position: center top;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    padding: 16px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;

    position: static;
    /* width: 500px; */
    height: 420px;
    left: 0px;
    top: 200.36px;

    /* Primary/White */

    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
    margin: 40px auto
}

.login-logo {
    position: static;
    width: 64px;
    height: 63.36px;
    top: 0px;
    background: url(../../assets/images/logo-man.png);
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 25px auto;
}

.login-title {
    position: static;
    width: 387px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #161616;
    margin: 8px auto;
    text-align: center;
}

.login-subtitle {
    position: static;
    width: 359px;
    height: 24px;
    left: 0px;
    top: 48px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #12784A;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px auto;
}

.login .ant-input {
    text-overflow: ellipsis;
    padding: 8px 16px;
    border-radius: 4px;
}

.login .ant-input-affix-wrapper {
    text-overflow: ellipsis;
    padding: 8px 16px;
    border-radius: 4px;
}

.login label {
    position: static;
    width: 52px;
    height: 24px;
    left: 0px;
    top: 0px;

    /* Heading/Small */

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.1px;

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
}

.login .ant-checkbox+span {
    padding-right: 8px;
    padding-left: 8px;
    font-family: 'Work Sans';
}

.login .login-btn {
    font-family: 'Work Sans';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;

    position: static;
    width: 100%;
    left: 40px;
    top: 324px;

    /* Secondary/Green */

    background: #12784A;
    color: #fff;
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
    border: 1px solid #158b67;
}

.registration-btn {
    font-family: 'Work Sans';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;

    position: static;
    width: 100%;
    left: 40px;
    top: 324px;

    /* Secondary/Green */

    background: #fff;
    color: rgb(0, 0, 0);
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
    border: 1px solid #12784A;
}

.login-btn:hover,
.login-btn:active,
.login-btn:focus {
    background-color: #158b67;
    color: #fff;
    border: 1px solid #158b67;
}

.login-footer {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #12784A;
    text-align: center;
}
@media only screen and (max-width: 768px) {

    .login-form {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .login-background {
        display: none;
    }

    .login-form {
        width: 100%;
        height: 100%;
    }

    .login-title {
        text-align: center;
        width: 100%;
    }

    .login-subtitle {
        text-align: center;
        width: 100%;
    }
}

.section-login {
    background: #E5E5E5;
    width: 100%;
    height: 100%;
    border: 1px solid #2d2d2d00;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div-login {
    color: #fff;
    /* background: #2d2d2d; */
    padding: 12px;
    display: inline-block;
}

.hubungi-admin {
    text-align: right;
    margin: 8px 0;
    margin-bottom: 24px;
}